document.addEventListener('DOMContentLoaded', () => {
  const link = document.querySelector('.footer__privacy__link');
  const modal = document.querySelector('.privacy-modal');
  const modalBody = document.querySelector('.privacy-modal__body');
  const closeButton = document.querySelectorAll('.close-modal');
  const body = document.querySelector('body');

  link.addEventListener('click', (e) => {
    openModal();
    e.preventDefault();
  })

  for (let el of closeButton) {
    el.addEventListener('click', () => {
      closeModal();
    });
  }

  // Ширина скролл бара
  const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
  
  const openModal = () => {
    modalBody.style.left = '50%';
    modal.classList.add('open');
    body.style.overflow = 'hidden';
  }

  const closeModal = () => {
    if (scrollBarWidth > 0) {
      modalBody.style.left = `calc(50% + ${scrollBarWidth / 2}px)`; // Компенсирую ширину скролла
    }
    modal.classList.remove('open');
    body.style.overflowY = 'auto';
  }
});